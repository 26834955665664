@use 'colors';
@use 'settings';
@import 'foundation-sites/scss/util/util';

.page-template-affiliate-layout {
  .overlapping-image {
    @include breakpoint(medium) {
      margin-left: -18%;
    }
  }

  .callout {
    background-color: colors.$languid-lavender;
    color: colors.$blue-independence;
    border: none;

    h4 {
      color: colors.$blue-independence;
    }
  }

  .list-icon {
    color: colors.$primary-color;
  }

  .overview-title {
    font-size: 1.125rem;
  }

  .overview-icon-column {
    min-width: 55px;
  }

  .benefit-list li {
    line-height: 2;
  }

  .brand-blue {
    color: colors.$brand-blue;
  }

  .button-padding-right {
    @include breakpoint(large) {
      padding-right: 15em;
    }

    @include breakpoint(medium only) {
      padding-right: 8em;
    }
  }

  .sign-up-button-column {
    @include breakpoint(large) {
      padding-right: 13.9em;
    }

    @include breakpoint(medium only) {
      padding-right: 6.9em;
    }
  }

  .z-index-2 {
    z-index: 2;
  }

  .tabs.affiliate-tabs {
    border: none;
    background: transparent;
  }

  .tabs-content.affiliate-tabs-content {
    @include breakpoint(small only) {
      border-radius: 0 0 settings.$global-radius settings.$global-radius;
    }

    @include breakpoint(medium) {
      border-radius: 0 settings.$global-radius settings.$global-radius settings.$global-radius;
    }

    border: none;
  }

  .tabs-title > a.affiliate-tabs-title {
    @include breakpoint(small only) {
      border-bottom: 1px solid colors.$chinese-silver;
    }

    @include breakpoint(medium) {
      border-radius: 2rem 2rem 0 0;
      min-width: 208px;
      text-align: center;
    }

    background-color: #fff;
    color: colors.$blue-independence;
    font-weight: normal;
    font-size: 18px;
  }

  .tabs-title.is-active > a.affiliate-tabs-title {
    font-weight: bold;
  }

  .accordion-title.affiliate-accordion-title {
    border: none;
    border-radius: settings.$global-radius;
    box-shadow: 0px 3px 6px #cecece26;
    color: colors.$pine-tree;
    font-weight: bold;
    padding-right: 2em;
    line-height: 1.5;
  }

  .accordion-content.affiliate-accordion-content {
    background-color: colors.$ghost-white;
    color: colors.$pine-tree;
    border: none;
    border-radius: none;
  }

  .accordion-title::before {
    content: '';
    color: colors.$brand-blue;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="%233398db"><path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z" /></svg>');
    background-position: center;
    background-repeat: no-repeat;
    width: 24px;
    height: 20px;
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }

  .is-active > .accordion-title::before {
    content: '';
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition-duration: 0.5s;
    -moz-transition-duration: 0.5s;
    -o-transition-duration: 0.5s;
    transition-duration: 0.5s;
  }
}
