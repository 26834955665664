.test-center-results {
  max-height: 400px;
  overflow: auto;
  margin-top: 10px;
  width: 100%;

  .column {
    margin-bottom: 10px;
  }
  .callout {
    margin-bottom: 0px;
  }
}

a.std-phone_number {
  color: #fff;
}
