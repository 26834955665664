@use 'colors';

// Meet Our Medical team
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.page-template-medical-team-layout {
  background-color: colors.$white;

  .wave-1,
  .wave-2 {
    position: absolute;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      z-index: -1;
      background-repeat: repeat;
      width: 100vw;
      background-position: bottom;
      background-image: url('data:image/svg+xml;utf8,<svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none" viewBox="0 0 1920 1558" width="1920" height="1558"><g transform="translate(0, 0)"><defs><path id="path-1623419673280200" d="M0 639.1296758104737 C0 639.1296758104737 1920 639.1296758104737 1920 639.1296758104737 C1920 639.1296758104737 1920.0000000000002 2022.291770573567 1920.0000000000002 2022.291770573567 C1920.0000000000002 2022.291770573567 1825.0000000000002 1855.2244389027458 1505 2074.7431421446363 C1185 2294.261845386534 807.0000000000001 2181.5885286783023 406.00000000000006 2022.291770573567 C5 1862.995012468827 0 1971.7830423940106 0 1971.7830423940106 C0 1971.7830423940106 0 639.1296758104737 0 639.1296758104737 Z" vector-effect="non-scaling-stroke"/></defs><g transform="translate(0, -639.1296758104737)"><path d="M0 639.1296758104737 C0 639.1296758104737 1920 639.1296758104737 1920 639.1296758104737 C1920 639.1296758104737 1920.0000000000002 2022.291770573567 1920.0000000000002 2022.291770573567 C1920.0000000000002 2022.291770573567 1825.0000000000002 1855.2244389027458 1505 2074.7431421446363 C1185 2294.261845386534 807.0000000000001 2181.5885286783023 406.00000000000006 2022.291770573567 C5 1862.995012468827 0 1971.7830423940106 0 1971.7830423940106 C0 1971.7830423940106 0 639.1296758104737 0 639.1296758104737 Z" style="stroke: rgb(140, 140, 140); stroke-width: 0; stroke-linecap: butt; stroke-linejoin: miter; fill: rgb(247, 248, 252);" vector-effect="non-scaling-stroke"/></g></g></svg>');
    }
  }

  .wave-1:after {
    top: 0;
    height: 850px;
  }

  .wave-2:after {
    bottom: 0;
    height: 600px;
    transform: rotate(180deg);
  }

  h1 {
    margin: 2rem 0 1rem;
  }

  .doctor-info {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    box-shadow: 0 3px 12px rgba(colors.$cool-gray, 0.3);
    border-radius: 1.4rem;
    padding: 1rem 1.4rem;
    margin-bottom: 1rem;

    .doctor-info-social-icons {
      display: flex;
      align-self: flex-end;
      min-height: 30px;

      a {
        margin: 0 0.5rem;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }

        i {
          font-size: 1.7rem;
        }

        .healthgrades-icon {
          height: 23px;
        }
      }
    }

    .doctor-info-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        height: 150px;
        width: 150px;
        border-radius: 100%;
        object-fit: cover;
        margin-bottom: 1rem;
      }
    }

    .doctor-info-box-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      color: #131516;

      h3 {
        font-size: 1.2rem;
      }

      h4 {
        font-size: 1rem;
        font-weight: normal;
        line-height: 1.3rem;
        text-align: center;
      }

      p {
        text-align: justify;
        line-height: 1.2rem;
      }
    }

    @media screen and (min-width: 40em) {
      h1 {
        font-size: 3.5rem;
      }

      .doctor-info-box {
        flex-direction: row;
        margin-bottom: 30px;

        img {
          margin: 0 1.5rem 0 0;
        }
      }

      .doctor-info-box-content {
        align-items: flex-start;

        h3 {
          margin-bottom: 0;
        }

        h4 {
          text-align: inherit;
        }

        p {
          text-align: inherit;
        }
      }
    }
  }
}
