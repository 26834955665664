@use 'colors';
@use 'settings';

// Find a Lab
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

$findalab-list-width: 320px;
$findalab-nav-color: colors.$secondary-color;
$findlab-radio-button-color: colors.$secondary-color;
settings.$findalab-search-background: #fef5e8;
settings.$findalab-loading-height: 250px;
settings.$findalab-loading-height-mobile: 250px;
$findalab-height: 250px !important;
$findalab-background: #f9f9f9 !default;
$findalab-border: 1px solid #e2e2e2 !default;
$findlab-user-location-color: #7a8186 !default;
$findalab-user-location-font-weight: 500 !default;
$findalab-radius: 6px !default;
$findalab-padding: 16px !default;
$findalab-day-filter-text-size: 18px !default;
$findlab-radio-button-checked-color: #ed9013 !default;
$findlab-radio-button-border: 2px solid #e3b77d !default;
$findlab-radio-button-checked-border: 2px solid #ed9013 !default;

.is-in-home {
  .findALabControls {
    display: none;
  }
}

.findALabControls {
  position: absolute;
  width: 278px;
  height: 900px !important;
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
  margin: 16px !important;

  br {
    display: none;
  }
}

.findalab-controls-mobile {
  height: auto !important;
  position: relative !important;
}

.findalab-container-mobile {
  gap: 16px;
}

findalab-results {
  flex-grow: 1;
  overflow-y: hidden;
}

.findalab,
.findalab__search {
  border: none;
  color: colors.$black !important;

  p {
    color: colors.$black !important;
  }
}

.findalab__search {
  padding: 0;

  .findalab__description {
    color: colors.$black;
    margin: 0;
  }
}

.std-findalab__input {
  border: 1px solid #e2e2e2;
  border-radius: 6px;
  font-size: 18px;
  margin: 0;
  padding: 6px;
  width: 100%;
  height: 2.5rem;

  &:placeholder {
    color: #e2e2e2;
  }
}

.std-findalab__input-menu {
  @extend .std-findalab__input;
  min-width: 11.1rem;
}

.std-findalab-menu-margin {
  margin: 20px;
}

.std-findalab-menu-show-dropdown-container {
  display: block;
}

.std-findalab__button {
  width: 100%;
  background: colors.$secondary-color;
}

.std-findalab__button:hover {
  background: colors.$light-secondary-color;
}

.findalab__nav {
  margin-top: 20px;
}

#std-center {
  @media (max-width: 767px) {
    margin: 0 10px;
  }
}

.ie10-link-fix {
  color: transparent !important;
}

.callout.with-findalab {
  padding: 0;

  .findalab {
    border: 0;
    border-radius: 0;
  }

  .callout__title {
    margin: 0;
  }
}

.findalab__search__title {
  color: colors.$black;
  font-size: rem-calc(26);
  margin-bottom: rem-calc(5);
}

.findalab__search__desc {
  font-size: rem-calc(14);
  margin-bottom: 1rem;
}

[data-findalab-day-filter] input {
  margin-bottom: 0;
}

[data-findalab-result-title] {
  line-height: 1;
}

.findalab__result--recommended__label {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: -3px;
}

.findalab__infowindow--recommended__label {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 7px;
}

.findalab__search__user-location {
  background: $findalab-background;
  border: $findalab-border;
  color: $findlab-user-location-color;
  font-weight: $findalab-user-location-font-weight;
  line-height: 1;
  margin-bottom: 20px;
  padding: 10px 8px;
  border-radius: $findalab-radius;

  &:hover {
    cursor: pointer;
  }
}

.findalab__search__day-filter {
  display: block;
  position: relative;

  label {
    font-size: $findalab-day-filter-text-size;
  }
}

.findalab__dayFilter {
  display: inline-block;
  position: relative;
  margin-left: 20px;
  padding: 0 20px;

  input[type='radio'] {
    position: absolute;
    visibility: hidden;
  }

  .findalab__radioButton {
    display: block;
    position: absolute;
    border: $findlab-radio-button-border;
    border-radius: 100%;
    height: 20px;
    width: 20px;
    bottom: 5px;
    margin-left: -24px;
  }

  .findalab__radioButton::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 14px;
    width: 14px;
    top: 1px;
    left: 1px;
    margin: auto;
  }

  input[type='radio']:checked ~ .findalab__radioButton {
    border: $findlab-radio-button-checked-border;
  }

  input[type='radio']:checked ~ .findalab__radioButton::before {
    background: $findlab-radio-button-checked-color;
  }

  input[type='radio']:checked ~ label {
    color: $findlab-radio-button-color;
  }
}

.input-group .std-findalab__button {
  border-radius: 0 0.5rem 0.5rem 0;
}

.findalab__container {
  min-height: 543px;
  @media all and (min-width: 1024px) {
    min-height: 950px;
  }
}
