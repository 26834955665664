@use 'mixins';
@import 'foundation-sites/scss/util/util';

.package-options-container,
.test-panel-package-options-container {
  .is-loading {
    @include mixins.loadingSpinner;
  }

  div {
    margin: auto;
  }
}

.package-options-container,
.package-options-container .is-loading {
  @include breakpoint(small) {
    min-height: 473px;
  }

  @include breakpoint(medium) {
    min-height: 345px;
  }
}

.std-descriptions,
.tests-information {
  h4 {
    margin-top: 1rem;
  }
}

@include breakpoint(small only) {
  .std-descriptions .column:first-child h4:first-child {
    margin-top: 0 !important;
  }
}

@include breakpoint(medium) {
  .std-descriptions h4:first-child {
    margin-top: 0;
  }
}

.test-best-time h3 {
  margin-bottom: 1rem;
}

.package-options-container {
  @include breakpoint(small only) {
    min-height: unset;
    margin-top: 2rem !important;
  }
}

.test-panel-package-options-container,
.test-panel-package-options-container .is-loading {
  @include breakpoint(small) {
    min-height: 278px;
  }

  @include breakpoint(medium) {
    min-height: 264px;
  }
}

.testing-process-container {
  @include breakpoint(small) {
    min-height: 313px;
  }

  @include breakpoint(large) {
    min-height: 192px;
  }
}

.testimonials-container {
  display: flex;
  align-items: center;

  @include breakpoint(small) {
    min-height: 282px;
  }

  @include breakpoint(medium) {
    min-height: 194px;
  }

  @include breakpoint(large) {
    min-height: 141px;
  }
}

.sample-results-cta {
  max-width: 25em;
  margin: 1em auto !important;

  @include breakpoint(medium only) {
    margin: 1em auto 0 auto !important;
  }
}

@include breakpoint(small only) {
  .popover_wistia_video_widget {
    margin-top: 1rem;
  }
}
