@use 'colors';
@use 'settings';
@import 'foundation-sites/scss/util/util';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Section
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.section {
  padding: 1.25rem 0;

  @include breakpoint(medium) {
    padding: 3rem 0;
  }

  // Colors
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  &.white {
    background-color: colors.$white;
    border-bottom: 1px solid colors.$light-gray;
    border-top: 1px solid colors.$light-gray;
  }

  &.primary {
    background: linear-gradient(rgba(colors.$light-secondary-color, 0.5), rgba(colors.$lighter-secondary-color, 0.5)),
      url('@images/bg-chevron.png');
    background-color: colors.$light-secondary-color;
    border-bottom: 1px solid colors.$light-gray;
    margin-bottom: -1px;

    &.invert {
      background-color: colors.$primary-color;
    }
  }

  &.secondary {
    background-color: colors.$lighter-secondary-color;

    &.invert {
      background-color: colors.$secondary-color;
    }
  }

  &.success {
    background-color: colors.$lighter-success-color;

    &.invert {
      background-color: colors.$success-color;
    }
  }

  &.warning {
    background-color: colors.$light-warning-color;

    &.invert {
      background-color: colors.$warning-color;
    }
  }

  &.alert {
    background-color: colors.$lighter-alert-color;

    &.invert {
      background-color: colors.$alert-color;
    }
  }

  &.orange {
    background: settings.$findalab-search-background;
  }

  // Invert
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  &.invert {
    background-color: colors.$dark-secondary-color;
    color: colors.$white;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: colors.$white;
    }

    .fa {
      color: colors.$white;
    }
  }

  // Sizes
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  &.tiny {
    padding: 0.5rem 0;
  }

  &.small {
    padding: 2rem 0;
  }

  &.large {
    padding: 4rem 0;
  }

  &.title {
    padding: 1.5rem 0 0.5rem;

    h1 {
      line-height: 1;
      margin: 0;
    }
  }

  // Modifiers
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  &.with-no-bottom {
    padding-bottom: 0;
  }

  &.with-no-top {
    padding-top: 0;
  }
}
