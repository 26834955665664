.selected-center-banner {
  $line-height: 1.5;
  $vertical-padding: 1rem;
  // These breakpoints are used to force the banner to have a height of X lines for each screen size, they are based on
  // a max length of 100 characters for the Selected Lab address text. em units are used to make the breakpoints adjust
  // if the user's browser is zoomed in
  $one-line-breakpoint: 44em;

  /**
   * Calculates the minimum height for the banner given the number of lines of text, including padding
   */
  @function min-height-for-num-lines($lines) {
    @return calc(($line-height * $lines * 1em) + $vertical-padding);
  }

  font-size: 0.875em;
  line-height: $line-height;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $one-line-breakpoint) {
    min-height: min-height-for-num-lines(2);
  }
}
