$font-path: '../font';

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src:
    url('https://fonts.gstatic.com/s/opensans/v40/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuU6FxZCJgg.woff2')
      format('woff2'),
    url('#{$font-path}/open-sans-italic-ext.woff2') format('woff2');
  unicode-range:
    U+0000-00FF,
    /* Basic Latin (includes A-Z, a-z, numbers, basic punctuation) */ U+2000-206F; /* General punctuation (spaces, dashes, quotes, etc.) */
}

@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src:
    url('https://fonts.gstatic.com/s/opensans/v40/memtYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWqWuU6FxZCJgg.woff2')
      format('woff2'),
    url('#{$font-path}/open-sans-italic-ext.woff2') format('woff2');
  unicode-range:
    U+0000-00FF,
    /* Basic Latin (includes A-Z, a-z, numbers, basic punctuation) */ U+2000-206F; /* General punctuation (spaces, dashes, quotes, etc.) */
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  font-display: swap;
  src:
    url('https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2')
      format('woff2'),
    url('#{$font-path}/open-sans-normal.woff2') format('woff2');
  unicode-range:
    U+0000-00FF,
    /* Basic Latin (includes A-Z, a-z, numbers, basic punctuation) */ U+2000-206F; /* General punctuation (spaces, dashes, quotes, etc.) */
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  font-display: swap;
  src:
    url('https://fonts.gstatic.com/s/opensans/v40/memvYaGs126MiZpBA-UvWbX2vVnXBbObj2OVTS-mu0SC55I.woff2')
      format('woff2'),
    url('#{$font-path}/open-sans-normal.woff2') format('woff2');
  unicode-range:
    U+0000-00FF,
    /* Basic Latin (includes A-Z, a-z, numbers, basic punctuation) */ U+2000-206F; /* General punctuation (spaces, dashes, quotes, etc.) */
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  font-display: swap;
  src: url('#{$font-path}/source-sans-pro-semibold.woff2') format('woff2');
  unicode-range:
    U+0000-00FF,
    /* Basic Latin (includes A-Z, a-z, numbers, basic punctuation) */ U+2000-206F; /* General punctuation (spaces, dashes, quotes, etc.) */
}
