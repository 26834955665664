.free__university__section {
  background-image: url('../../images/free_university_section.png');
  background-position: top;
  background-repeat: no-repeat;
}

.university__section__content,
.free__university__section {
  h3 {
    color: #484e64;
    font-size: 32px;
  }

  h4 {
    font-size: 16px;
    color: #484e64;
  }

  p {
    color: #484e64;
    text-align: left;
  }

  .row .row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.university__section__title {
  position: relative;

  img {
    z-index: -999;
  }

  h1 {
    position: absolute;
    top: 0;
    color: #ffffff;
    margin: 0 0.5rem;
    font-size: 3.5rem;
  }
}

.free__university__section__title {
  color: #273148;
  font-size: 4rem;
}

.get__universities__by_state__section,
.free__university__section {
  select,
  input {
    border-color: #e09339;
  }

  .university__continue__button,
  select,
  input {
    height: 46px !important;
  }

  input {
    margin: 0;
  }

  label {
    color: #484e64 !important;
    font-size: 18px;
    font-weight: bolder;
  }
}

@media only screen and (max-width: 639px) {
  .get__universities__by_state__section,
  .free__university__section {
    padding: 0.5rem;

    div.small-12.padding-right-1 {
      padding-right: 0 !important;
      margin-bottom: 1rem;
    }
  }

  .university__section__title {
    h1 {
      top: 0;
      font-size: 1.5rem;
    }
  }

  .free__university__section__title {
    h1 {
      margin-top: 0;
      font-size: 1.5rem;
    }
  }
}
