@use 'colors';

.scrollbar::-webkit-scrollbar {
  width: 6px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: colors.$medium-gray;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: colors.$lighter-gray;
}
