@use 'sass:color';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Colors
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Brand
$brand-orange: #ed9013;
$brand-blue: #3398db;
$brand-gray: #bdc3c7;

// Warm
$red: #eb2c27;
$orange: $brand-orange;
$yellow: #ecf05d;

// Cool
$green: #809c58;
$blue: #5b90b3;
$purple: #6d30c4;
$dark-blue: #263248;
$blue-independence: #484e64;
$blue-gunmetal: #273148;
$cool-gray: #8290b4;
$languid-lavender: #d0d1dd;
$chinese-silver: #cccac9;
$pine-tree: #2e2f2b;
$ghost-white: #f7f8fc;

//Other medology brands
$healthlabs-blue: #256ca2;

// Primary
$primary-color: $orange;
$dark-primary-color: color.adjust($orange, $lightness: -10%);
$darker-primary-color: color.adjust($orange, $lightness: -20%);
$light-primary-color: color.adjust($orange, $lightness: 10%);
$lighter-primary-color: color.adjust($orange, $lightness: 20%);

// Secondary
$secondary-color: $blue;
$active-secondary-color: color.adjust($blue, $lightness: -15%);
$dark-secondary-color: color.adjust($blue, $lightness: -20%);
$darker-secondary-color: color.adjust($blue, $lightness: -40%);
$light-secondary-color: color.adjust($blue, $lightness: 20%);
$lighter-secondary-color: color.adjust($blue, $lightness: 40%);

// Success
$success-color: $green;
$dark-success-color: color.adjust($green, $lightness: -20%);
$darker-success-color: color.adjust($green, $lightness: -40%);
$light-success-color: color.adjust($green, $lightness: 20%);
$lighter-success-color: color.adjust($green, $lightness: 40%);

// Warning
$warning-color: $yellow;
$dark-warning-color: color.adjust($yellow, $lightness: -20%);
$darker-warning-color: color.adjust($yellow, $lightness: -40%);
$light-warning-color: color.adjust($yellow, $lightness: 20%);
$lighter-warning-color: #fcfcf5;

// Alert
$alert-color: $red;
$dark-alert-color: color.adjust($red, $lightness: -20%);
$darker-alert-color: color.adjust($red, $lightness: -40%);
$light-alert-color: color.adjust($red, $lightness: 20%);
$lighter-alert-color: color.adjust($red, $lightness: 40%);

// Grayscale
$medium-gray: #a2a5a7;
$lighter-gray: color.adjust($medium-gray, $lightness: 25%);
$light-gray: color.adjust($medium-gray, $lightness: 15%);

//matches image background
$lighter-gray: #f7f7f7;
$dark-gray: color.adjust($brand-gray, $lightness: -40%);
$darker-gray: color.adjust($brand-gray, $lightness: -80%);
$black: color.adjust($brand-gray, $lightness: -68%);
$off-white: color.adjust($brand-gray, $lightness: 20%);
$white: #fff;

$foundation-palette: (
  primary: $primary-color,
  secondary: $secondary-color,
  success: $success-color,
  warning: $warning-color,
  light-primary: $light-primary-color,
  alert: $alert-color,
  muted: $light-gray,
  dark: $dark-secondary-color,
  brand-blue: $brand-blue,
);

.has-white-color {
  color: $white !important;
}

.text-has-orange-color {
  color: $brand-orange !important;
}

.text-has-blue-color {
  color: $brand-blue !important;
}

.has-alert-color {
  color: $alert-color !important;
}
