@use 'colors';

.referral-block {
  > .wrap-collapsible {
    margin-bottom: 1.2rem;

    input[type='checkbox'] {
      display: none;
    }

    .label-toggle {
      display: block;
      font-weight: bold;
      color: colors.$blue;
      cursor: pointer;
      transition: all 0.25s ease-out;
    }

    .label-toggle:hover {
      color: colors.$darker-gray;
    }

    .label-toggle::before {
      content: ' ';
      display: inline-block;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 5px solid currentColor;
      vertical-align: middle;
      margin-right: 0.7rem;
      transform: rotate(90deg) translateY(-3px);
      transition: transform 0.2s ease-out;
    }

    .toggle:checked + .label-toggle::before {
      transform: translateX(2px);
    }

    .collapsible-content {
      max-height: 350px;
      font-size: 0.8rem;
      overflow: hidden;
      transition: max-height 0.25s ease-in-out;
    }

    .toggle:checked + .label-toggle + .collapsible-content {
      max-height: 0;
    }

    .collapsible-content .content-inner {
      padding: 0.5rem 1rem;
    }
  }
}
