@use 'colors';
@use 'settings';
@import 'foundation-sites/scss/util/util';

.steps {
  background-color: colors.$lighter-secondary-color;
  border: settings.$callout-border;
  border-radius: settings.$callout-radius;
  margin: 2rem 0 0;

  @include breakpoint(large) {
    height: 10rem;

    .row,
    .column {
      height: 100%;
    }
  }

  &.notification-steps {
    @include breakpoint(large) {
      height: rem-calc(115);
    }
  }

  .column:last-child .steps__step--chevron {
    display: none;
  }
}

.steps__step {
  padding: 0.5rem;

  @include breakpoint(large) {
    height: 100%;
    padding: 1.5rem;
    position: relative;
  }
}

.steps__step--text {
  margin: 0;

  @include breakpoint(large) {
    padding-right: 1rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

//overrides steps__step
.steps__title {
  background: colors.$secondary-color;
  border-radius: 0.4rem 0.4rem 0 0;

  @include breakpoint(large) {
    border-radius: 0.4rem 0 0 0.4rem;
    padding: rem-calc(12);
  }
}

.steps__title--text {
  color: colors.$white;
}

.steps__step--chevron {
  display: none;
  height: 100%;
  left: 100%;
  margin-bottom: 6px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 1px;

  @include breakpoint(large) {
    display: block;
  }
}

.steps__step--chevron::before {
  background: rgba(colors.$black, 0.25);
  content: '';
  height: 50%;
  left: 0;
  position: absolute;
  top: 0;
  transform: skew(9deg, 0deg);
  width: 100%;
}

.steps__step--chevron::after {
  background: rgba(colors.$black, 0.25);
  bottom: 0;
  content: '';
  height: 50%;
  position: absolute;
  right: 0;
  transform: skew(-9deg, 0deg);
  width: 100%;
}
