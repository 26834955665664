@mixin loadingSpinner {
  position: relative;
  pointer-events: none;
  min-height: inherit;
  &:after {
    animation: spinAround 1s infinite linear;
    content: '\f110';
    font: var(--fa-font-solid);
    display: block;
    position: absolute;
    top: calc(50% - 1em);
    left: calc(50% - 1em);
    font-size: 2em;
  }
}
