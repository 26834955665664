.test-price {
  transition: background-color 0.3s ease;
  margin: 0;
  padding-top: 12px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
}

.test-price:hover {
  background-color: #eeeeee;
  cursor: pointer;
}

@media screen and (min-width: 64em) {
  .large-centered {
    margin-left: auto;
    margin-right: auto;
  }

  .large-centered,
  .large-centered:last-child:not(:first-child) {
    float: none;
    clear: both;
  }
}
