ul.sitemap-menu {
  margin-left: 0 !important;
  break-inside: avoid-column;

  li {
    display: block;
    break-inside: avoid-column;
    margin-bottom: 10pt;

    a {
      font-size: 16pt;
      font-weight: bold;
      color: #355a73;
    }

    ul {
      margin-top: 5pt;
      list-style-type: none;
    }

    &:before {
      display: none;
    }
  }
}

.sitemap-main {
  columns: 1;
  margin-left: 5pt;

  br {
    display: none;
  }
}

@media screen and (min-width: 995px) {
  .sitemap-main {
    columns: 2;
  }
}

ul.sitemap-menu > li > a,
ul.sitemap-menu > li > ul > li > a {
  font-family: 'Open Sans', Arial, sans-serif;
  font-weight: normal;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

#menu-item-10177 > ul {
  display: none;
}
