@use 'sass:color';
@use 'colors';
@use 'settings';
@import 'foundation-sites/scss/util/util';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Table
// scss-lint:disable SelectorDepth
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.table {
  margin-bottom: 1rem;
  width: inherit;

  @include breakpoint(small only) {
    overflow-x: scroll;
  }

  table {
    margin-bottom: 0;
  }

  thead {
    background-color: transparent;
    border: 0;
    color: colors.$white;
  }
}

.table__stripe {
  background: smart-scale(settings.$table-background, settings.$table-color-scale);
}

.table__emphasis {
  thead & {
    background: settings.$anchor-color;
  }
}

.table__secondary {
  thead & {
    background: colors.$medium-gray;
  }
}

.table__empty {
  background-color: transparent;
}

.table__spacer {
  background-color: colors.$lighter-secondary-color !important;
}

.why-choose-us-table {
  .fa {
    color: colors.$success-color;
  }

  th:not(:first-child),
  td:not(:first-child) {
    text-align: center;
  }

  th:nth-of-type(3) {
    background: colors.$primary-color;
    color: colors.$white;
  }

  thead,
  tbody,
  tfoot {
    border: 0;
  }

  tbody tr:nth-child(even) {
    background-color: color.adjust(colors.$white, $lightness: -5%);
  }

  th,
  td {
    border-right: 1px solid colors.$light-gray;
  }

  th:first-child,
  td:first-child {
    border: 0;
  }

  thead th {
    background-color: colors.$dark-gray;
    border-color: color.adjust(colors.$dark-gray, $lightness: -5%);
    color: colors.$white;
    text-align: center;

    &:first-child {
      background-color: transparent;
      border-top: 0;
      width: 275px;
    }
  }

  tbody tr td:first-child {
    background-color: colors.$secondary-color;
    color: colors.$white;
    font-weight: bold;
  }

  tbody tr:nth-child(even) td:first-child {
    background-color: color.adjust(colors.$secondary-color, $lightness: -5%);
  }
}
