@use 'colors';

.payments__options__section {
  padding-bottom: 4rem;
  font-family: Open Sans;
  font-size: 15px;
  color: colors.$blue-independence;
  text-decoration: none solid colors.$blue-independence;
  line-height: 28px;
}

.payment__options__first__section > strong > .lead {
  line-height: 39px !important;
}

.payments__options__section_background {
  background-color: white;
  height: 100%;
  width: 100%;
  z-index: -1;
}

.payment__options__section__wave_1 {
  background-image: url('data:image/svg+xml;utf8,<svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none" viewBox="0 0 1920 1558" width="1920" height="1558"><g transform="translate(0, 0)"><defs><path id="path-1623419673280200" d="M0 639.1296758104737 C0 639.1296758104737 1920 639.1296758104737 1920 639.1296758104737 C1920 639.1296758104737 1920.0000000000002 2022.291770573567 1920.0000000000002 2022.291770573567 C1920.0000000000002 2022.291770573567 1825.0000000000002 1855.2244389027458 1505 2074.7431421446363 C1185 2294.261845386534 807.0000000000001 2181.5885286783023 406.00000000000006 2022.291770573567 C5 1862.995012468827 0 1971.7830423940106 0 1971.7830423940106 C0 1971.7830423940106 0 639.1296758104737 0 639.1296758104737 Z" vector-effect="non-scaling-stroke"/></defs><g transform="translate(0, -639.1296758104737)"><path d="M0 639.1296758104737 C0 639.1296758104737 1920 639.1296758104737 1920 639.1296758104737 C1920 639.1296758104737 1920.0000000000002 2022.291770573567 1920.0000000000002 2022.291770573567 C1920.0000000000002 2022.291770573567 1825.0000000000002 1855.2244389027458 1505 2074.7431421446363 C1185 2294.261845386534 807.0000000000001 2181.5885286783023 406.00000000000006 2022.291770573567 C5 1862.995012468827 0 1971.7830423940106 0 1971.7830423940106 C0 1971.7830423940106 0 639.1296758104737 0 639.1296758104737 Z" style="stroke: rgb(140, 140, 140); stroke-width: 0; stroke-linecap: butt; stroke-linejoin: miter; fill: rgb(247, 248, 252);" vector-effect="non-scaling-stroke"/></g></g></svg>');
  padding: 3rem 0 0 0;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 850px;
}

.payments__options__section__wave_2 {
  z-index: -1;
  height: 100%;
  width: 100%;
  background-image: url('data:image/svg+xml;utf8,<svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none" viewBox="0 0 1920 1558" width="1920" height="1558"><g transform="translate(0, 0)"><defs><path id="path-1623419673280200" d="M0 639.1296758104737 C0 639.1296758104737 1920 639.1296758104737 1920 639.1296758104737 C1920 639.1296758104737 1920.0000000000002 2022.291770573567 1920.0000000000002 2022.291770573567 C1920.0000000000002 2022.291770573567 1825.0000000000002 1855.2244389027458 1505 2074.7431421446363 C1185 2294.261845386534 807.0000000000001 2181.5885286783023 406.00000000000006 2022.291770573567 C5 1862.995012468827 0 1971.7830423940106 0 1971.7830423940106 C0 1971.7830423940106 0 639.1296758104737 0 639.1296758104737 Z" vector-effect="non-scaling-stroke"/></defs><g transform="translate(0, -639.1296758104737)"><path d="M0 639.1296758104737 C0 639.1296758104737 1920 639.1296758104737 1920 639.1296758104737 C1920 639.1296758104737 1920.0000000000002 2022.291770573567 1920.0000000000002 2022.291770573567 C1920.0000000000002 2022.291770573567 1825.0000000000002 1855.2244389027458 1505 2074.7431421446363 C1185 2294.261845386534 807.0000000000001 2181.5885286783023 406.00000000000006 2022.291770573567 C5 1862.995012468827 0 1971.7830423940106 0 1971.7830423940106 C0 1971.7830423940106 0 639.1296758104737 0 639.1296758104737 Z" style="stroke: rgb(140, 140, 140); stroke-width: 0; stroke-linecap: butt; stroke-linejoin: miter; fill: rgb(247, 248, 252);" vector-effect="non-scaling-stroke"/></g></g></svg>');
  padding: 3rem 0;
  background-position: bottom;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  top: 66%;
}

@media only screen and (max-width: 1100px) {
  .payments__options__section__wave_2 {
    top: 64%;
  }
}

@media only screen and (max-width: 640px) {
  .payment__options__section__wave_1 {
    height: 1065px;
  }
  .payments__options__section__wave_2 {
    top: 60%;
  }
  .payments__options__margin {
    margin-top: 3rem !important;
  }
}

.is-odd-flex-dir-row-reverse:nth-child(odd) {
  flex-direction: row-reverse;
}

.is-even-margin-top-4:nth-child(even) {
  margin-top: 4rem;
}
