@use 'sass:color';
@use 'colors';

.page-template-marketing-how-to-get-tested,
.page-template-privacy-layout,
.page-template-doctor-consultation-layout,
.page-template-affiliate-layout {
  background-color: colors.$white;

  h1 {
    font-weight: 800;
  }

  .video-wrapper {
    background-color: colors.$blue-gunmetal;
    box-shadow: 0 5px 15px color.adjust(colors.$blue-gunmetal, $alpha: 0.25);
    border-radius: 30px;
    overflow: hidden;
    max-width: 615px;
    margin: 0 auto 3rem;
  }

  .step {
    font-family: 'Helvetica Neue', 'Open Sans';
    color: colors.$blue-independence;
    margin-bottom: 1rem;

    img {
      width: 100%;
      max-width: 520px;
      max-height: 348px;
      object-fit: contain;
      display: block;
      margin: 0 auto;
    }

    button {
      font-family: 'Open Sans';
    }
  }

  .marketing-consultation-wrapper {
    margin-bottom: 1.5rem;
    font-family: 'Helvetica Neue', 'Open Sans';
    color: colors.$blue-independence;

    h3 {
      font-family: 'Helvetica Neue', 'Open Sans';
      color: colors.$blue-independence;
    }
  }

  .wave-1,
  .wave-2,
  .wave-3 {
    position: absolute;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      z-index: -1;
      background-repeat: repeat;
      width: 100vw;
      background-position: bottom;
      background-image: url('data:image/svg+xml;utf8,<svg version="1.2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" overflow="visible" preserveAspectRatio="none" viewBox="0 0 1920 1558" width="1920" height="1558"><g transform="translate(0, 0)"><defs><path id="path-1623419673280200" d="M0 639.1296758104737 C0 639.1296758104737 1920 639.1296758104737 1920 639.1296758104737 C1920 639.1296758104737 1920.0000000000002 2022.291770573567 1920.0000000000002 2022.291770573567 C1920.0000000000002 2022.291770573567 1825.0000000000002 1855.2244389027458 1505 2074.7431421446363 C1185 2294.261845386534 807.0000000000001 2181.5885286783023 406.00000000000006 2022.291770573567 C5 1862.995012468827 0 1971.7830423940106 0 1971.7830423940106 C0 1971.7830423940106 0 639.1296758104737 0 639.1296758104737 Z" vector-effect="non-scaling-stroke"/></defs><g transform="translate(0, -639.1296758104737)"><path d="M0 639.1296758104737 C0 639.1296758104737 1920 639.1296758104737 1920 639.1296758104737 C1920 639.1296758104737 1920.0000000000002 2022.291770573567 1920.0000000000002 2022.291770573567 C1920.0000000000002 2022.291770573567 1825.0000000000002 1855.2244389027458 1505 2074.7431421446363 C1185 2294.261845386534 807.0000000000001 2181.5885286783023 406.00000000000006 2022.291770573567 C5 1862.995012468827 0 1971.7830423940106 0 1971.7830423940106 C0 1971.7830423940106 0 639.1296758104737 0 639.1296758104737 Z" style="stroke: rgb(140, 140, 140); stroke-width: 0; stroke-linecap: butt; stroke-linejoin: miter; fill: rgb(247, 248, 252);" vector-effect="non-scaling-stroke"/></g></g></svg>');
    }
  }

  .marketing-main-section p {
    line-height: 2;
  }

  .wave-1:after {
    top: 0;
    height: 850px;
  }

  .wave-2:after {
    bottom: 0;
    height: 600px;
    transform: rotate(180deg);
  }

  .wave-3:after {
    top: -96px;
    height: 2000px;
    transform: rotate(180deg);
  }

  @media screen and (min-width: 40em) {
    h1 {
      font-size: 3rem;
      margin: 1.5rem auto 0;
    }

    .video-wrapper {
      margin-bottom: 10rem;
    }

    .step {
      margin-bottom: 8rem;
    }

    .marketing-consultation-wrapper {
      margin: 0 0 4rem;

      h3 {
        margin-bottom: 2rem;
      }
    }
  }

  @media screen and (min-width: 64em) {
    .wave-2:after {
      bottom: -115px;
    }

    .marketing-consultation-wrapper {
      margin: 0 11rem 2rem;
    }
  }
}
