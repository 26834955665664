@use 'sass:color';
@use 'colors';
@import 'foundation-sites/scss/util/util';

$foundation-palette: colors.$foundation-palette;

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Footer
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.site-footer {
  background: linear-gradient(to bottom, colors.$dark-secondary-color 0, colors.$darker-secondary-color 100%);
  background-color: colors.$darker-secondary-color;
  padding: 1rem 0;

  @include breakpoint(medium) {
    padding: 2rem 0;
  }

  hr {
    background-color: colors.$secondary-color;
  }

  .fa {
    color: colors.$secondary-color;
  }

  p,
  a:not(.button) {
    color: colors.$white;
  }

  a:not(.button):hover {
    color: color.adjust(colors.$white, $lightness: -20%);
  }

  h1,
  h2,
  h3,
  h4,
  .h4,
  h5,
  .h5,
  h6 {
    color: colors.$lighter-secondary-color;

    .fa {
      margin-right: 0.5rem;
    }
  }
}

.colophon {
  background-color: colors.$darker-secondary-color;
  padding-bottom: 3rem;

  @include breakpoint(small only) {
    padding-bottom: 5rem;
  }

  a,
  .fa {
    color: colors.$dark-gray;
  }
}

.colophon__copyright {
  color: colors.$dark-gray;
  margin-bottom: 0;
}

.colophon__signature {
  color: colors.$white;
  margin-bottom: 0;

  a {
    color: colors.$white;
    text-decoration: underline;

    &:hover {
      color: color.adjust(colors.$white, $lightness: -20%);
    }
  }
}

.colophon__social {
  float: none;
  padding: 1rem 0;

  @include breakpoint(medium) {
    float: right;
    padding: 0;
  }
}

.colophon__img {
  height: 4rem;
}

.made-with-love {
  letter-spacing: 0.25px;
  color: white;
  line-height: 1.78;

  @media screen and (min-width: 769px) {
    float: right;
  }

  @media screen and (max-width: 768px) {
    margin-top: 10px;
  }

  a {
    color: white;
    text-decoration: underline;
  }
}
