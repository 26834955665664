@use 'colors';

.stats-container {
  color: colors.$secondary-color;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
  padding: 5px;

  .numbers {
    font-size: 1.8rem;
  }

  .border-secondary {
    padding: 12px 4px;
    height: 100%;
    width: 100%;

    .row {
      margin: 0;
      display: inline-block;
    }
  }
}

.article-body {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.lab_title_container {
  background: colors.$secondary-color;
  padding: 5px 10px;
  text-align: center;
  border-radius: 10px;

  .lab_title {
    color: colors.$white;
    font-size: 1.1rem;
    font-weight: bold;
  }
}

.lab_address_container {
  color: colors.$dark-gray;
  padding: 0.5rem;

  .hours_container {
    margin-bottom: 0.5rem;
  }

  .closed_lab {
    text-align: center;
    color: colors.$dark-gray;
    margin-bottom: 0.8rem;
  }
}

.border-right {
  border-right: 1px solid colors.$medium-gray;
}
