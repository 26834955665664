// Wistia video CSS
// - - - - - - - - - - - - - - - -

.wistia_popover_loading {
  background-image: url('https://fast.wistia.com/assets/images/blank.gif');
  height: 100%;
  left: 0;
  opacity: 1;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.5);
  .fa-spinner {
    position: fixed;
    left: 45%;
    top: 42%;
    font-size: 8em;
    opacity: 1;
    color: #fff;
  }
}
.wistia_responsive_padding {
  .wistia_play_button_background {
    height: 38px;
    position: absolute;
    width: 60px;
    z-index: 1;
    background-color: rgba(235, 142, 39, 0.76);
    left: 50%;
    top: 50%;
    margin: -19px -30px 0;
    transition: all 80ms ease-out 0s;
    mix-blend-mode: normal;
  }
  &:hover {
    .wistia_play_button_background {
      height: 100%;
      width: 100%;
      background-color: rgba(235, 142, 39, 0.5);
      left: 0;
      top: 0;
      margin: 0;
      mix-blend-mode: multiply;
    }
  }
}
.wistia_play_button {
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAP4AAACiCAYAAABh2nDdAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAulJREFUeNrs3d1tGlEQBtAlSgEpgXSAO8AdxB2QDuwONhWQDpxUAB14OyAdmA5IB+SOvDdCSHnLn/WdI91XP4z1aYY77DIMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAMAvnc/nQzsrlYCs4JdTOxvVgKzgd7t23qkKZAW/PLezVhnICn43qg7kBb88tbNUJcgKfr/4+6BSkBX8buviD/KCf7bzh8zgd/cqB3nBt/OH0OD3i7+1KkJW8O38ITj4/eJvqaKQFXw7fwgNfvfo4i/PQgleV/D/0J8+tnO3WCy+qXKGN0pAU5/3D3b+Oj5ZHf/SNHf/7yqu45Nj3Y7n/AWfQHXZV4/5bpXCqE/GqH+tLvw+uvjT8cmymrv/Ril0fHI6/qX93P1d/Ak+QcEvx8HO36hPnOXwsvMflULHJ6fjX5oGO38dnzjr4WXn72EfwSdM7fx3XvBp1Cdn1L9m56/jE6jv/D3so+MT1PEv2fnr+ASqC7+Dh30EnzzLefQflcKoT8aof60u/Grnf/Sf1PHJsZpHfzt/wSfMz52/Ugg+WWrk/6oMgk+OL+3c+oLPv/dWCfgLapdfO/29Uuj4ZJjauRF6wSfHpxb4Wys8oz4ZjvNoPymFjk+G/TzaC73gE6Au8B5a4L2Rx6hPCM/h6/iE+dwCfyP0Oj45o73dvOATZBq8XdeoT5S+mxd6HZ8Ax8Ev6Oj4ROm7eaEXfAL0Czyf5436hPCqLB2fMH03L/Q6PiGj/Z3v2ev45Kiwvxd6wSfHg908/KfOv1+97nqlspAT/Ec/aw05wT/5UQvICn6N9kuVhJzgjyoIOcE/+alqyAr+zgUe5AS/uvy9ikFO8O3mISz4W6M95ATfbh7Cgv9kNw9ZwR9VBXKC/+wCD7KCbzcPYcHfqAIAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAvE4/BBgAB9Vp0xXzB8AAAAAASUVORK5CYII=')
    0px 0px / 60px 38px no-repeat transparent;
  cursor: pointer;
  display: block;
  height: 38px;
  outline: none;
  position: absolute;
  width: 60px;
  z-index: 1;
  margin: -19px -30px 0;
  left: 50%;
  top: 50%;
}
.wistia_thumbnail_img {
  border: 0;
  display: block;
  float: none;
  height: 100%;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
}
.wistia_container {
  position: relative;
  height: 100%;
  width: 100%;
}
.wistia_mousedown {
  cursor: pointer;
  display: block;
  height: 100%;
  overflow: hidden;
  outline: none;
  position: relative;
  width: 100%;
}
.wistia_embed {
  display: inline-block;
  height: 100%;
  width: 100%;
}
