@use 'mixins';
@import 'foundation-sites/scss/util/util';

.waiting-angular {
  @include mixins.loadingSpinner;

  * {
    display: none;
  }
}

.is-loading-inline {
  @include mixins.loadingSpinner;

  &:after {
    top: calc(50% - 0.5em);
    left: 0;
    font-size: 1em;
  }

  span {
    visibility: hidden;
  }
}

.width-100 {
  width: 100% !important;
}

@media only screen and (max-width: 639px) {
  .margin-top-mobile-1 {
    margin-top: 1rem;
  }
}

@include breakpoint(medium only) {
  .margin-top-medium-only-2 {
    margin-top: 2rem;
  }
}
