.button {
  display: flex;
  align-items: center;
  justify-content: center;

  &.animated-left {
    overflow: hidden;
    position: relative;
    top: 0px;
    left: 0px;

    &:hover:after {
      left: -3rem;
      transform: skewX(0deg);
    }

    &:after {
      transition: background-color 300ms, width 300ms, height 300ms, top 300ms, right 300ms, transform 300ms;
      content: ' ';
      display: block;
      background-color: rgba(255, 255, 255, 0.1);
      width: 2.8rem;
      height: 150%;
      position: absolute;
      top: -10px;
      left: -15px;
      transform: skewX(15deg);
    }
  }

  &.animated-right {
    overflow: hidden;
    position: relative;
    top: 0px;
    left: 0px;

    &:hover:after {
      right: -3.2rem;
      transform: skewX(0deg);
    }

    &.large:hover:after {
      right: -3.5rem;
    }

    &:after {
      transition: background-color 300ms, width 300ms, height 300ms, top 300ms, right 300ms, transform 300ms;
      content: ' ';
      display: block;
      background-color: rgba(255, 255, 255, 0.1);
      width: 3rem;
      height: 150%;
      position: absolute;
      top: -10px;
      right: -15px;
      transform: skewX(-15deg);
    }

    &.hollow::after {
      border: 1px solid;
      background-color: transparent;
    }
  }

  &:before {
    font-family: 'Font Awesome 6 Free';
    font-weight: 900;
  }

  &.large {
    min-height: 56px;
    &:after {
      width: 3.5rem;
    }
  }

  &.big {
    height: 45px;
  }

  &.medium {
    height: 38px;
  }

  &.small {
    &:after {
      width: 2.8rem;
    }

    height: 34px;
  }

  &.tiny {
    &:after {
      width: 2.8rem;
    }

    height: 28px;
  }
}
.right-align-text {
  padding-right: 1.5rem;
}

.left-align-text {
  padding-left: 1.5rem;
}

.left-icon:before {
  position: absolute;
  left: 1rem;
}

.right-icon:before {
  position: absolute;
  right: 0.6rem;
}

.text-white {
  color: white;
  &:before {
    color: white;
  }
}

.text-black {
  color: black;
  &:before {
    color: black;
  }
}
