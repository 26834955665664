@use 'colors';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Styleguide
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// .sg {}

.sg__section {
  padding: 80px 0;
}

.sg__title {
  font-size: 36px;
}

.sg__heading {
  border-bottom: 2px solid #cbcdcd;
  display: block;
  margin-bottom: 20px;
  padding-bottom: 5px;
}

// Logo
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.sg__logo {
  margin-bottom: 20px;
}

.sg__logo-img {
  height: auto;
  max-width: 100%;
}

.sg__logo-img--inverse {
  background: colors.$black;
  padding: 10px;
}

// Colors
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.sg__colors {
  list-style-type: none;
  margin: 0;
  padding: 0;

  & > li {
    box-sizing: border-box;
    float: left;
    height: 100px;
    margin-bottom: 10px;
    margin-right: 10px;
    padding-top: 40px;
    text-align: center;
    width: 100px;
  }
}
