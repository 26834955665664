@use 'colors';
@import 'foundation-sites/scss/util/util';

// Global text styles
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.price-text {
  color: colors.$green;
  display: block;
  font-weight: 700;
  margin-bottom: 1rem;

  @include breakpoint(medium) {
    display: inline-block;
    float: right;
  }
}

.attn-text {
  color: colors.$dark-secondary-color;
}

.small-text {
  font-size: 75%;
}

.medium-text {
  font-size: 85%;
}

.large-text {
  font-size: 125%;
}

.underline {
  text-decoration: underline;
}

.unstyled-list {
  list-style: none;
}

.check-list__item {
  color: colors.$dark-secondary-color;
  font-size: rem-calc(14);
  font-weight: 700;
  margin: 0;
  margin-bottom: 0.5rem;
}

.bold-list {
  font-weight: 700;
  list-style: none;
  margin-left: 0;
}

.subtle-text {
  color: colors.$dark-gray;
  font-weight: 500;
}

.subscript-text {
  display: block;
  font-size: rem-cal(12);
  font-style: italic;
  font-weight: 500;

  @include breakpoint(medium) {
    display: inline-block;
    font-size: rem-calc(14);
  }

  .callout.primary & {
    color: colors.$darker-primary-color;
  }
}

.no-subscript {
  margin-bottom: 20px;
}

.has-subscript {
  margin-bottom: -5px;
}

.no-wrap {
  white-space: nowrap;
}
