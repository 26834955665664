@use 'settings';
@import 'foundation-sites/scss/util/util';

// Side
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

@mixin side($align, $width) {
  display: inline-block;
  vertical-align: $align;
  width: $width;
}

@mixin side-fixed($align, $width) {
  @include side($align, $width);
}

@mixin side-expanded($align, $width, $fallback-width) {
  @include side($align, $fallback-width);
  width: calc(100% - #{$width});
}

.side__fixed {
  @include side-fixed(middle, 60px);
}

.side__fixed__selling__points {
  @include side-fixed(middle, 70px);
}

.side__expanded {
  @include side-expanded(middle, 65px, 85%);
}

.side__expanded__selling__points {
  @include side-expanded(middle, 75px, 85%);
}

.side--input {
  padding-right: 0;
  input,
  label {
    line-height: settings.$global-lineheight;
  }

  @include breakpoint(large) {
    input {
      line-height: 1.8;
    }
  }

  .side__fixed {
    @include side-fixed(top, 18px);
  }

  .side__expanded {
    @include side-expanded(top, 24px, 90%);
  }
}
