@use 'sass:color';
@use 'colors';
@use 'settings';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// How it Works
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//
// Widget displaying the STDcheck custom process
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// How it works Widget
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.howitworks {
  background: colors.$white;
  border-radius: settings.$global-radius;
  box-shadow: settings.$global-box-shadow;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  width: 100%;
}

.howitworks__icon {
  align-items: center;
  background: colors.$dark-secondary-color;
  border-radius: settings.$global-radius 0 0 settings.$global-radius;
  display: flex;
  justify-content: center;
  padding: 35px 20px;
  text-align: center;

  i {
    color: colors.$white;
    width: 1em;
  }
}

.howitworks__text {
  flex: 1;
  padding: 10px;
}

// How it Works Section
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.howitworks-section__icon {
  min-width: 92px;
  text-align: center;
}

.howitworks-section__link {
  border-color: colors.$white !important;
  color: colors.$white !important;
  margin-bottom: 0;
  padding-top: 10px;

  &:hover {
    color: color.adjust(colors.$white, $lightness: -10%);
  }
}
