.hello-bar__container {
  box-shadow: 0 1px 3px 2px rgb(0 0 0 / 15%);
  position: relative;
}

.hello-bar__content {
  font-weight: 600 !important;
  font-family: 'Source Sans Pro' !important;
  font-size: 19.6px;
  gap: 4px 20px;
  padding: 10px;
}

.hello-bar__button {
  background-color: #22b38c;
  height: auto;
  border-radius: 7px;
  box-shadow: 0px 4px 20px -7px black;
  font-size: 20px;
  padding: 2px 22px;
  text-transform: none !important;

  &:hover {
    background-color: #22b38c;
  }
}
