@use 'colors';

// Medical team
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.single-medical-team {
  .medical-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px 0 15px;

    img {
      border-radius: 300px;
      max-width: 200px;
    }
  }

  h5 {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8rem;
    color: colors.$brand-blue;
    margin-bottom: 0;
  }

  h1 {
    text-align: center;
  }

  .social-icons {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      display: flex;
      margin: 0 0.5rem;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      i {
        font-size: 1.7rem;
      }

      .healthgrades-icon {
        height: 23px;
      }
    }
  }

  p {
    margin: 40px 0;
    text-align: justify;
  }

  @media screen and (min-width: 40em) {
    .medical-image img {
      width: 300px;
      height: 300px;
      max-width: inherit;
    }

    p {
      margin: 40px 0 60px;
      text-align: inherit;
    }
  }
}
