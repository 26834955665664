@use 'colors';
@use 'settings';
@use '@fortawesome/fontawesome-free/scss/fontawesome' with (
  $fa-font-path: settings.$fa-font-path,
  $fa-font-display: settings.$fa-font-display
);
@import 'foundation-sites/scss/util/util';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Header
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// @content Top of the website's page. Includes the logo,
//          menu, and general site info.
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Header Bar
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// @content Very top bar on the site that holds the company
//          slogan, contact information and link to my account.
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.header-bar {
  background-color: colors.$dark-secondary-color;
  color: colors.$lighter-secondary-color;
}

.header-bar__contact a {
  color: colors.$lighter-secondary-color;
}

.header-bar--small {
  a {
    color: colors.$white;
  }
}

.header-bar__span {
  font-size: 80%;
  font-style: italic;
  font-weight: normal;
}

.header-bar__button {
  @include breakpoint(medium) {
    margin-bottom: 0;
    margin-left: 0.5rem;
  }
}

.header-bar--fixed-height {
  padding: 0.5rem 0;
  align-items: center;
  // These min-heights are to ensure the header bar and coupon applied widget always have the same height
  // the relative em units are essential for this to work when the user changes the font size by zooming in/out
  min-height: 5.5em;
  @media all and (min-width: 22em) {
    min-height: 4em;
  }
  @media all and (min-width: 51em) {
    min-height: 2.75em;
  }
}

#menu-icon {
  cursor: pointer;
}

// Header
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// @content The main header area that holds the logo and
//          main global navigation.
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.has-fixed-header {
  @include breakpoint(medium) {
    padding-top: 77px;
  }
}

@media only screen and (min-width: 40.063em) {
  .medium-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
    clear: both;
  }
}

@media screen and (min-width: 64em) {
  .large-uncentered {
    position: static;
    margin-left: 0;
    margin-right: 0;
    float: left;
  }
  .large-3 {
    width: 25%;
  }
}

.header {
  background-color: colors.$white;
  border-bottom: 1px solid colors.$light-gray;
  padding: 0.4rem 0;
  width: 100%;
  z-index: 1000;

  @include breakpoint(medium) {
    padding: 1rem 0;
  }

  &.is-fixed {
    @include breakpoint(medium) {
      background-color: rgba(colors.$white, 0.96);
      position: fixed;
      top: 0;
      z-index: 1000;
    }
  }

  .menu {
    > li {
      display: table-cell;
    }
    .vertical {
      > li {
        display: block;
      }
    }
  }
}

.header__menu {
  display: inline-block;
  float: left;
  font-size: rem-calc(14px);

  @include breakpoint(large) {
    float: right;
  }

  .is-current::after {
    border-bottom: 2px dotted colors.$dark-secondary-color;
    content: '';
    display: block;
    height: 2px;
    margin: 0 auto;
    width: 26px;
  }

  .submenu .is-current {
    background-color: colors.$lighter-secondary-color;

    &::after {
      display: none;
    }
  }

  ul:first-child {
    display: flex;
    align-items: flex-end;

    > li {
      flex-shrink: 0;
      flex-grow: 0;
    }
  }
}

.header__menu__button {
  margin-left: 1rem;
}

.header__menu__form {
  padding: 1rem;

  .input-group {
    margin-bottom: 0.5rem;
  }
}

.city_overlay {
  background: rgba(92, 144, 180, 0.7);
  overflow: hidden;
  height: 100%;
  width: 100%;
  z-index: 2;
  color: colors.$white;

  h1,
  p,
  strong {
    color: colors.$white;
  }
}

// Small Header
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// @content A simplified header for smaller screens, includes
//          a menu button (for off-canvas) and logo.
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// .small-header {}

.small-header__img {
  padding-right: 0.5rem;
}

.small-header__menu-link {
  display: block;
  margin-top: 0.2rem;
  text-align: center;

  span {
    color: colors.$darker-secondary-color;
    display: block;
    font-size: 0.7rem;
    font-weight: bold;
    line-height: 1;
    margin: 0 auto;
    padding-top: 0.3rem;
  }
}

.small-header__menu-icon {
  margin: 0 auto;
  background: colors.$darker-secondary-color;
  border-radius: settings.$global-radius;
  color: colors.$white;
  font-size: 1.5rem;
  padding: 0.3rem 0.5rem;

  .is-open-left &::before {
    content: fontawesome.$fa-var-times;
  }
}

.coupon-applied-header {
  .coupon-applied-menu-buttons {
    margin-top: -0.5rem;
    margin-left: 1rem;

    a {
      padding: 0.5rem 0;
      text-align: right;
    }

    button {
      margin-left: 0.5rem;
    }
  }

  .dropdown.menu > li > a {
    @media screen and (min-width: 1024px) and (max-width: 1200px) {
      padding-left: 0.1rem;
    }
  }
}

#menu .is-dropdown-submenu-parent:hover ul {
  display: block;
}
