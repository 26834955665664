@use 'colors';

// PSC page CSS
// - - - - - - - - - - - - - - - -
table.labHours {
  display: none;
  border-collapse: collapse;
  tbody {
    tr {
      td,
      th {
        padding: 0.5rem 0 0.5rem 0.8rem;
        div {
          font-size: 1rem;
        }
        div:nth-child(2) {
          font-size: 0.8rem;
        }
      }
      th {
        div {
          text-align: left;
        }
      }
    }
  }
  &.labHours-small {
    display: none;
    tbody {
      tr {
        td,
        th {
          div {
            font-size: 0.8rem;
            &.lab-hours-no-lunch {
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
            }
          }
          div:nth-child(2) {
            font-size: 0.6rem;
          }
        }
        th {
          padding-left: 0.8rem;
          padding-right: 0;
        }
        td {
          padding-left: 0;
          padding-right: 0.8rem;
          div {
            text-align: right;
          }
        }
      }
    }
  }
}

span.hours-show-hide {
  span {
    cursor: pointer;
    color: colors.$brand-blue;
  }
  span:nth-child(2) {
    display: none;
  }
}

#findNearLabSubmitError {
  display: none;
}
