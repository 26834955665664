@use 'sass:map';
@use 'colors';
@use 'settings';
@import 'foundation-sites/scss/util/util';

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// Trumps
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// @content Trumps are often 'hacks' or quick fixes to
//          overwrite the code. It will always be called
//          last in the stylesheet, so it will overwrite
//          styles without having to add important tags or
//          be over complicated with the specificity.
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// scss-lint:disable ImportantRule

// Utilities
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

*[data-ng-cloak] {
  display: none;
}

.inline-block {
  display: inline-block;
}

.text-capitalize {
  text-transform: capitalize;
}

.with-short-line-height {
  line-height: 1;
}

.large-flex-center {
  @include breakpoint(large) {
    align-items: center;
    display: flex;
    justify-content: space-around;
  }
}

.medium-flex-center {
  @include breakpoint(medium) {
    align-items: center;
    display: flex;
    justify-content: space-around;
  }
}

.with-flex-center {
  @include breakpoint(medium down) {
    align-items: center;
    display: flex;
    justify-content: space-around;
  }
}

@media screen and (min-width: 40em) {
  .with-flex-center {
    align-items: center;
    display: flex;
    justify-content: space-around;
  }
}

.small-flex-center {
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.with-margin-top {
  margin-top: 1rem !important;
}

.with-margin-top-medium {
  @include breakpoint(medium down) {
    margin-top: 1rem !important;
  }
}

.with-margin-bottom {
  margin-bottom: 1rem !important;
}

.with-no-margin {
  margin: 0 !important;
}

.with-no-border {
  border: none !important;
}

.with-no-bottom {
  margin-bottom: 0 !important;
}

@each $name, $color in colors.$foundation-palette {
  .#{$name}-color {
    color: $color !important;
  }
}

.display-inline {
  display: inline;
}

.medium-text-center {
  @include breakpoint(medium) {
    text-align: center;
  }
}

.small-text-right {
  text-align: right;
}

.small-text-left {
  text-align: left;
}

.small-text-center {
  @include breakpoint(small only) {
    text-align: center;
  }
}

.medium-text-right {
  @include breakpoint(medium) {
    text-align: right;
  }
}

.small-only-float-left {
  @include breakpoint(small only) {
    float: left;
    margin-right: 0.5rem;
  }
}

.medium-float-right {
  @include breakpoint(medium) {
    float: right;
  }
}

.text-columns {
  column-gap: 2em;
  columns: rem-calc(400) 2;
}

.text-columns__no-break {
  break-inside: avoid;
}

.form-columns {
  padding: 0;
}

.center-line-height-two {
  vertical-align: middle;
  line-height: 2;
}

@each $direction in ('left', 'right', 'top', 'bottom') {
  .margin-#{$direction} {
    margin-#{$direction}: 1rem;
  }
}

// Button
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.small-expanded {
  @include breakpoint(small only) {
    width: 100%;
  }
}

.with-margin {
  margin-left: 1rem;

  &.small-expanded {
    @include breakpoint(small only) {
      margin-left: 0;
    }
  }
}

.borderless {
  margin-top: 0.9rem;
  font-size: inherit;
  border: 0 !important;
  padding: 0;
  text-decoration: underline;
}

// Callout
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// @require Zurb's Foundation
// @link http://foundation.zurb.com/sites/docs/callout.html
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.callout__title:first-child {
  background: colors.$dark-secondary-color;
  border-bottom: 1px solid colors.$light-gray;
  border-radius: settings.$callout-title-radius settings.$callout-title-radius 0 0;
  color: colors.$white !important;
  line-height: 1;
  margin-bottom: settings.$callout-padding;
  margin-left: -(settings.$callout-padding);
  margin-right: -(settings.$callout-padding);
  margin-top: -(settings.$callout-padding);
  padding: settings.$callout-padding;

  .callout.small & {
    margin-bottom: 0.5rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    margin-top: -0.5rem;
    padding: 0.5rem;
  }

  .callout.large & {
    margin-bottom: 3rem;
    margin-left: -3rem;
    margin-right: -3rem;
    margin-top: -3rem;
    padding: 3rem;
  }
}

.callout.small .close-button {
  top: 0;
}

.callout:not(.small):last-child {
  @include breakpoint(medium) {
    margin-bottom: 0;
  }

  .callout-group & {
    margin-bottom: 1rem;
  }
}

@each $name, $color in colors.$foundation-palette {
  .callout.#{$name} .callout__title {
    background: $color;
    color: $color;
  }

  .callout.#{$name} {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $color;
    }
  }

  .callout.#{$name} .close-button {
    color: $color;
  }

  .border-#{$name} {
    border: 0.098rem solid $color;
    border-radius: 0.5rem;
  }
}

.callout__title {
  > strong,
  > a {
    color: colors.$white;
  }

  a {
    text-decoration: underline;
  }

  small {
    line-height: inherit;
  }
}

.callout.with-table {
  overflow: hidden;

  table thead,
  table tbody,
  table tfoot {
    background: transparent;
    border: 0;
  }

  table tbody tr:nth-child(even) {
    background: transparent;
  }

  table {
    th:first-child,
    td:first-child {
      padding-left: 0;
    }
  }

  table td:last-child {
    padding-right: 0;
  }

  table thead td {
    padding-top: 0;
  }

  table tfoot td {
    padding-bottom: 0;
  }

  table thead,
  table tbody {
    border-bottom: 1px dashed rgba(colors.$black, 0.25);
  }
}

.callout.muted {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: colors.$dark-gray;
  }
}

.callout.with-image {
  @include breakpoint(medium) {
    overflow: hidden;
    padding-bottom: 0;
    padding-left: 0;
  }
}

// Input Group
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// @require Zurb's Foundation
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.input-group-button .button {
  font-size: settings.$input-font-size;
  height: 2.6rem;
  padding: 0.5rem 1rem;
}

// Dropdown
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// @require Zurb's Foundation
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Wistia and menu dropdown overlap due to z-index issues.
.is-dropdown-submenu {
  z-index: 10;
}

// Menu
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// @require Zurb's Foundation
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.menu {
  .is-current-category {
    font-weight: bold;
  }
}
.menu {
  .menu-text {
    background-color: colors.$dark-secondary-color;
    color: colors.$white;
  }

  .menu-text a {
    background-color: colors.$dark-secondary-color;
    color: colors.$white;
    padding: 0;
  }

  .menu-text a:hover {
    background: transparent;
    cursor: default;
  }

  .button {
    display: inline-block;
  }
}

.menu.white {
  background-color: colors.$white;
}

[data-accordion-menu] {
  .menu {
    display: none;
  }
}

.off-canvas.is-transition-overlap {
  z-index: settings.$reveal-zindex;
}

.menu.faq {
  > li:not(:last-child) {
    border-bottom: settings.$hr-border;
  }

  .question {
    color: settings.$body-font-color;
    padding-left: 0;
  }

  .answer {
    li {
      padding: 0 0 1rem 1rem;

      a {
        display: inline-block;
        padding: 0;
      }
    }
  }
}

.current-menu-item > a,
.current-menu-ancestor > a {
  font-weight: 700;
}

// Off Canvas
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// @require Zurb's Foundation
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.off-canvas.position-left {
  height: 100%;
}

.off-canvas .close-button {
  color: colors.$dark-gray;
}

.off-canvas__img {
  margin: 2rem 0.5rem 1rem;
  width: 80%;
}

.off-canvas__button {
  margin: settings.$menu-item-padding;
}

// Abide
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// @require Zurb's Foundation
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.form-error {
  display: block;
}

// Tabs
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// @require Zurb's Foundation
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.tabs {
  border-radius: settings.$global-radius settings.$global-radius 0 0;
  overflow: hidden;
}

.tabs-title {
  .with-ellipsis & {
    @include breakpoint(medium) {
      max-width: 230px;

      > a {
        overflow: hidden;
        padding: 1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &:not(.my-account__tab) {
    width: 100%;

    @include breakpoint(medium) {
      width: auto;
    }
  }

  > a {
    font-weight: 700;
    padding: 0.5rem;

    @include breakpoint(medium) {
      font-size: rem-calc(14);
      padding: settings.$tab-item-padding;
    }

    .my-account__tab & {
      font-size: rem-calc(14);
    }
  }

  > a:focus,
  > a[aria-selected='true'] {
    color: colors.$white;
  }
}

.tabs-content {
  border-radius: 0 0 settings.$global-radius settings.$global-radius;
}

.tabs-title > a:focus,
.tabs-title > a[aria-selected='true'] {
  color: colors.$white;
}

// Progress Bar
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// @require Zurb's Foundation
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.progress-meter {
  width: 100%;
}

.progress-meter-text {
  &.left-aligned {
    left: 5px;
    line-height: 1.3;
    top: 0;
    transform: none;
  }
}

// Font Awesome Icons
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// @require Font Awesome
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.fa-check,
.fa-check-circle,
.fa-check-circle-o {
  color: colors.$success-color;
}

.fa-circle {
  color: colors.$secondary-color;
}

.fa-li {
  line-height: settings.$paragraph-lineheight;
  top: 0;

  &.fa-close {
    color: colors.$alert-color;
  }
}

.fa-inverse {
  color: colors.$white;
}

// Toggle Hours
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
[data-std-hours-table] {
  display: none;
}

//  Blockquote
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

blockquote {
  background: rgba(colors.$light-gray, 0.25);
  border-radius: 0 5px 5px 0;
  font-style: italic;
  margin: 1rem;
  position: relative;

  &::before,
  &::after {
    color: colors.$light-gray;
    font: var(--fa-font-solid);
    font-size: 1rem;
    line-height: 1;
    position: absolute;
  }

  &::before {
    content: '\f10d';
    left: 0;
    top: 0;
    padding: rem-calc(5);
  }

  &::after {
    content: '\f10e';
    right: 0;
    bottom: 0;
    padding: rem-calc(5 8);
  }
}

//  Findalab
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.callout.with-findalab {
  padding: 0;

  .findalab {
    border: 0;
    border-radius: 0;
  }

  .callout__title {
    margin: 0;
  }
}

.findalab__search__title {
  color: colors.$black;
  font-size: rem-calc(26);
  margin-bottom: rem-calc(5);
}

.findalab__search__desc {
  font-size: rem-calc(14);
  margin-bottom: 1rem;
}

[data-findalab-day-filter] input {
  margin-bottom: 0;
}

[data-findalab-result-title] {
  line-height: 1;
}

.findalab__result--recommended__label {
  display: inline-block;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: -3px;
}

.findalab__infowindow--recommended__label {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 7px;
}

// Accordion Menu
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
// @require Zurb's Foundation
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Changes the arrow of the accordion menu to a font awesome icon
.is-accordion-submenu-parent > a i {
  border: 0;
  margin-top: 0;
}

.is-accordion-submenu-parent[aria-expanded='true'] > a i.fa-angle-double-right {
  display: none;
}

.is-accordion-submenu-parent[aria-expanded='true'] > a i.fa-angle-double-down {
  display: inline-block !important;
}

// Adds a current page style to the accordion menu
// @require currentAccordionMenu.js
.is-accordion-submenu-parent.is-current > a {
  font-weight: bold;
}

.is-accordion-submenu-item.is-current > a {
  font-weight: bold;
}

// Badge Generator
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.block {
  display: block;
  overflow-x: auto;

  img {
    max-width: none;
  }
}

// STD Floating
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.floating-container {
  background: colors.$white;
  display: none;
  margin-top: 66px;
  padding: 0.5rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}

// Foundation Datepicker
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.datepicker td.active.day,
.datepicker td.active.year {
  background: map.get(colors.$foundation-palette, primary);
  color: colors.$white;
  font-weight: bold;
}

// Pagination
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.pagination-next button::after {
  content: '\00bb';
  display: inline-block;
  margin-left: 0.5rem;
}

.pagination-previous button::before {
  content: '\00ab';
  display: inline-block;
  margin-right: 0.5rem;
}

// Iframes
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.iframe {
  overflow-y: scroll;
}

.iframe__target {
  min-width: 300px;
}

// Lead Section
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.lead-img {
  float: right;
  margin: 0 0 1rem 1rem;
  width: 130px;

  @include breakpoint(medium) {
    width: 150px;
  }
}

.lead-icon {
  color: colors.$dark-secondary-color;
  float: right;
  font-size: 6rem;
  margin: 0 0 1rem 1rem;

  @include breakpoint(medium) {
    font-size: 10rem;
  }
}

// Zurb's Foundation Reveal Modal
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.pac-container {
  z-index: 1016 !important;
}

.modal__title {
  background: colors.$dark-secondary-color;
  border-bottom: 1px solid colors.$light-gray;
  color: colors.$white;
  font-weight: bold;
  line-height: 1;
  margin-bottom: settings.$reveal-padding;
  margin-left: -(settings.$reveal-padding);
  margin-right: -(settings.$reveal-padding);
  margin-top: -(settings.$reveal-padding);
  padding: settings.$reveal-padding;

  &.clean {
    background: colors.$off-white;
    border-bottom: 1px solid colors.$dark-gray;
    color: colors.$dark-secondary-color;
  }
}

// Contact Support
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.margin-left-one {
  margin-left: 1rem;
}

.contact-support-icon {
  max-width: 17px;
  padding-bottom: 5px;
}

// HIPPA Logo
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.hipaa-logo {
  max-width: 180px;
}

// Override foundation lineheight to fix bug in foundation 6.2.4
// (https://github.com/zurb/foundation-sites/issues/10416)
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea,
select {
  height: auto;
}

// Type
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}

// Floats
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.float-right {
  float: right;
}

.float-left {
  float: left;
}

// Text Transformation
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.u-capitalize {
  text-transform: capitalize;
}

// Clearfix
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.clearfix {
  overflow: auto;
  zoom: 1;
}

// Centering
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.center {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

// Margin
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.margin__bottom {
  margin-bottom: 30px;
}

@for $i from 1 through 6 {
  @include breakpoint(small only) {
    .small-margin-bottom-#{$i} {
      margin-bottom: #{$i * 1rem};
    }
  }
}

// Visibility
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.hide {
  display: none !important;
}

// Trumps
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// Order checkout iframe object
#s_s {
  float: left;
  margin-right: 10px;
}

// Livechat button
.livechat_button a {
  color: colors.$white;
  text-decoration: none;
  &:hover {
    color: colors.$white;
  }
}

// BBB
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.bbb {
  background-color: #eff6fa;
  border-radius: 6px;
  margin-bottom: 25px;
  padding: 8px;
}

// Find a Lab
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

.findalab__search__title {
  color: colors.$black;
  font-size: 26px;
  margin-bottom: 5px;
}

.findalab__search__desc {
  font-size: 14px;
  margin-bottom: 16px;
}

.find-lab__button {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  height: 42px !important;
}

// As seen on
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
.center-items {
  display: flex;
  align-items: center;
}

// Breadscrumb fix
.breadscrumb-row {
  height: 1.0625rem;
}

// get selected widget margin adjust
.get-selected {
  margin: 0 0 1rem !important;
}

.is-flex {
  display: flex;
}

.margin-one-half {
  margin: 0.5rem;
}

.font-bolder {
  font-weight: bolder;
}

.font-size-one-and-a-quarter {
  font-size: 1.25rem;
}

.is-black {
  color: colors.$black;
}

.justify-flex-content-center {
  justify-content: center;
}

.flex-align-items-center {
  align-items: center;
}

.z-index-max {
  z-index: 2147483647 !important;
}

//Force LiveChat to be on top of everything
#chat-widget-container {
  z-index: 2147483647 !important;
}
