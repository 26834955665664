@use 'colors';
@use 'settings';
@import 'foundation-sites/scss/util/util';

.error404__container {
  align-items: center;
  justify-content: center;
  background: linear-gradient(rgba(colors.$light-secondary-color, 0.5), rgba(colors.$lighter-secondary-color, 0.5)),
    url('@images/bg-chevron.png');
  background-color: colors.$light-secondary-color;
  display: flex;
  padding: rem-calc(50) 0;

  @include breakpoint(medium) {
    min-height: 50%;
    min-height: 50vh;
  }

  .row {
    width: 100%;
  }
}

.error404__title {
  font-size: rem-calc(60);
  font-weight: bold;
  line-height: 1;
  margin-bottom: 2rem;
  margin-top: settings.$callout-padding;
  padding: 0;
  text-transform: uppercase;
}

.error404__title-number {
  color: colors.$primary-color;
  font-size: 0.9em;
}

.error404__title-word {
  font-size: rem-calc(50);
}

.error404__text {
  font-size: rem-calc(24);
  line-height: 1.2;
}
